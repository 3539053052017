<template>
  <div class="allTemplate">
    <div class="templateLeft" :style="{ height: treeHeight }">
      <div class="company">
        <span class="el-icon-caret-bottom"></span>
        基础档案
      </div>
      <ul>
        <li
          v-for="(item, index) in treeData"
          :key="index"
          class="treeLi"
          @click="setTab(item)"
        >
          <span :class="{ treeLis: parent == item.typeCode }">
            {{ item.docName }}
          </span>
        </li>
      </ul>
    </div>
    <div class="templateRight">
      <div class="searchflex">
        <div class="searchLeft">
          <div class="searchInput">
            <el-input
              size="mini"
              v-model="search"
              @input="getSearch()"
              placeholder="请输入内容"
              suffix-icon="el-icon-search"
            ></el-input>
          </div>
        </div>
        <div class="searchRight">
          <img
            src="@/assets/image/add_new.png"
            v-if="treeData && treeData.length > 0"
            class="addIcon"
            @click="add()"
          />
        </div>
      </div>
      <yh-table
        tableBorder
        :tableHeight="tableHeight"
        :tableLoading="tableLoading"
        :tableHead="tableHead"
        :tableData="tableData"
      >
        <template #tableRow="{ row, item }">
          <span>{{ row[item.prop] }}</span>
        </template>
        <el-table-column label="操作" width="300" align="center" fixed="right">
          <template slot-scope="scope">
            <yh-popover
              popoverTitle="修改"
              @savePopUp="edit(scope.row)"
            ></yh-popover>
            <yh-popover
              popoverOnly="1"
              popoverTitle="删除"
              @savePopUp="del(scope.row)"
            >
              确定删除该信息吗？
            </yh-popover>
          </template>
        </el-table-column>
      </yh-table>
      <div class="pages">
        <el-pagination
          :current-page="page"
          :total="total"
          :page-size="size"
          :page-sizes="[20, 30, 40, 50]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <yh-dialog :dialogObj="dialogObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formHead="formHead"
        :formData="formData"
      ></yh-form>
      <template #footer>
        <el-button size="mini" type="primary" v-if="prepare" @click="submit()">
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 部门列表
      treeHeight: "",
      parent: "",
      treeData: [],

      // 表格
      search: "",
      tableLoading: false,
      tableHeight: "100px",
      tableHead: [],
      tableData: [],
      page: 1,
      size: 20,
      total: 0,

      dialogObj: {
        dialogTitle: "新增",
        dialogOpen: false,
        dialogWidth: "500px",
      },
      formHead: [],
      formData: {},

      prepare: true,
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.getDocDefinitions();
  },
  methods: {
    // 部门
    getDocDefinitions() {
      this.treeData = [];
      this.$axios.get("docDefinitions", {}, (res) => {
        const status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.treeData = res.data;
          if (!this.parent) {
            if (this.treeData && this.treeData.length > 0) {
              this.parent = this.treeData[0].typeCode;
              this.getDocDefinitionsDetail();
            }
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getDocDefinitionsDetail() {
      this.$axios.get("docDefinitionsDetail", { id: this.parent }, (res) => {
        const status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.tableHead = [];
          this.formHead = [];
          let tableHead = res.data.fieldMetas;
          for (let i = 0; i < tableHead.length; i++) {
            this.tableHead.push({
              prop: this.underline2Hump(tableHead[i].fieldCode),
              label: tableHead[i].fieldName,
              fieldType: tableHead[i].fieldType,
              fixed: false,
              minWidth: "120",
            });

            if (tableHead[i].fieldType == "String") {
              this.formHead.push({
                fieldType: tableHead[i].fieldType,
                comp: "el-input",
                label: tableHead[i].fieldName,
                prop: this.underline2Hump(tableHead[i].fieldCode),
                widthSize: 1,
              });
            } else if (
              tableHead[i].fieldType == "Integer" ||
              tableHead[i].fieldType == "Decimal"
            ) {
              this.formHead.push({
                fieldType: tableHead[i].fieldType,
                comp: "el-input",
                label: tableHead[i].fieldName,
                prop: this.underline2Hump(tableHead[i].fieldCode),
                bind: {
                  type: "Number",
                },
                widthSize: 1,
              });
            } else if (tableHead[i].fieldType == "Date") {
              this.formHead.push({
                fieldType: tableHead[i].fieldType,
                comp: "el-date-picker",
                label: tableHead[i].fieldName,
                prop: this.underline2Hump(tableHead[i].fieldCode),
                bind: {
                  type: "date",
                  valueFormat: "yyyy-MM-dd",
                },
                widthSize: 1,
              });
            } else if (tableHead[i].fieldType == "Datetime") {
              this.formHead.push({
                fieldType: tableHead[i].fieldType,
                comp: "el-date-picker",
                label: tableHead[i].fieldName,
                prop: this.underline2Hump(tableHead[i].fieldCode),
                bind: {
                  type: "datetime",
                  valueFormat: "yyyy-MM-dd HH:mm:ss",
                },
                widthSize: 1,
              });
            } else if (tableHead[i].fieldType == "Array") {
              this.formHead.push({
                fieldType: tableHead[i].fieldType,
                comp: "el-input",
                label: tableHead[i].fieldName,
                prop: this.underline2Hump(tableHead[i].fieldCode),
                placeholder: "请输入数组，以逗号进行分割",
                widthSize: 1,
              });
            } else if (tableHead[i].fieldType == "Enum") {
              let a = tableHead[i].fieldSettings.split("&");
              let b = a[0].split("=");
              let c = a[1].split("=");
              let d = b[1].split(",");
              let arr = [];
              for (let g = 0; g < d.length; g++) {
                let e = d[g].split("-");
                arr.push({
                  id: e[0],
                  name: e[1],
                });
              }
              if (c[1] == "true") {
                this.formHead.push({
                  fieldType: tableHead[i].fieldType,
                  comp: "el-select",
                  sonComp: "el-option",
                  label: tableHead[i].fieldName,
                  prop: this.underline2Hump(tableHead[i].fieldCode),
                  widthSize: 1,
                  bind: {
                    multiple: true,
                    collapseTags: true,
                  },
                  data: arr,
                });
              } else {
                this.formHead.push({
                  fieldType: tableHead[i].fieldType,
                  comp: "el-select",
                  sonComp: "el-option",
                  label: tableHead[i].fieldName,
                  prop: this.underline2Hump(tableHead[i].fieldCode),
                  widthSize: 1,
                  data: arr,
                });
              }
            } else if (tableHead[i].fieldType == "Reference") {
              let a = tableHead[i].fieldSettings.split("&");
              let b = a[0].split("=");
              let c = a[1].split("=");
              let d = a[2].split("=");
              let e = d[1].split(",");
              this.$axios.get("docEntries", { code: b[1] }, (res) => {
                const status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  if (c[1] == "true") {
                    this.formHead.push({
                      fieldType: tableHead[i].fieldType,
                      comp: "el-select",
                      sonComp: "el-option",
                      label: tableHead[i].fieldName,
                      prop: this.underline2Hump(tableHead[i].fieldCode),
                      widthSize: 1,
                      name: e[0],
                      bind: {
                        multiple: true,
                        collapseTags: true,
                      },
                      data: res.data,
                    });
                  } else {
                    this.formHead.push({
                      fieldType: tableHead[i].fieldType,
                      comp: "el-select",
                      sonComp: "el-option",
                      label: tableHead[i].fieldName,
                      prop: this.underline2Hump(tableHead[i].fieldCode),
                      name: e[0],
                      widthSize: 1,
                      data: res.data,
                    });
                  }
                } else {
                  this.$message.error(res.data.message);
                }
              });
            }
          }
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    setTab(item) {
      this.parent = item.typeCode;
      this.getDocDefinitionsDetail();
    },
    // 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
    underline2Hump(word) {
      return word.replace(/_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    getSearch() {
      this.page = 1;
      this.getData();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      this.$axios.get(
        "docEntries",
        {
          code: this.parent,
          perPage: this.size,
          page: this.page,
          q: this.search,
        },
        (res) => {
          const status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.tableData = res.data;
            this.total = Number(res.headers["x-total-count"]);
            for (let j = 0; j < this.tableData.length; j++) {
              for (let i = 0; i < this.formHead.length; i++) {
                if (this.formHead[i].fieldType == "Array") {
                  this.tableData[j][this.formHead[i].prop] =
                    this.tableData[j][this.formHead[i].prop].join(",");
                } else if (this.formHead[i].fieldType == "Enum") {
                  for (let s = 0; s < this.formHead[i].data.length; s++) {
                    if (
                      this.formHead[i].data[s].id ==
                      this.tableData[j][this.formHead[i].prop]
                    ) {
                      this.tableData[j][this.formHead[i].prop] =
                        this.formHead[i].data[s].name;
                    }
                  }
                } else if (this.formHead[i].fieldType == "Reference") {
                  for (let s = 0; s < this.formHead[i].data.length; s++) {
                    if (
                      this.formHead[i].data[s].id ==
                      this.tableData[j][this.formHead[i].prop]
                    ) {
                      this.tableData[j][this.formHead[i].prop] =
                        this.formHead[i].data[s][this.formHead[i].name];
                    }
                  }
                }
              }
            }
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    add() {
      this.formData = {};
      for (let i = 0; i < this.tableHead.length; i++) {
        this.$set(this.formData, this.tableHead[i].prop, "");
      }
      this.dialogObj.dialogTitle = "新增";
      this.dialogObj.dialogOpen = true;
    },
    edit(row) {
      console.log(this.formHead);

      this.formData = row;
      console.log(this.formData);
      this.dialogObj.dialogTitle = "修改";
      this.dialogObj.dialogOpen = true;
    },
    del(row) {
      this.$axios.jsonPost(
        "docEntriesDel",
        { code: this.parent, id: row.id },
        (res) => {
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.$message.success("删除成功");
            this.getData();
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    submit() {
      let formData = JSON.parse(JSON.stringify(this.formData));
      for (let i = 0; i < this.tableHead.length; i++) {
        if (this.tableHead[i].fieldType == "Array") {
          if (formData[this.tableHead[i].prop]) {
            formData[this.tableHead[i].prop] =
              formData[this.tableHead[i].prop].split(",");
          } else {
            this.$set(formData, this.tableHead[i].prop, []);
          }
        } else if (
          this.tableHead[i].fieldType == "Integer" ||
          this.tableHead[i].fieldType == "Decimal"
        ) {
          if (formData[this.tableHead[i].prop]) {
            formData[this.tableHead[i].prop] = Number(
              formData[this.tableHead[i].prop]
            );
          } else {
            formData[this.tableHead[i].prop] = 0;
          }
        } else if (this.tableHead[i].fieldType == "Reference") {
          if (!formData[this.tableHead[i].prop]) {
            formData[this.tableHead[i].prop] = null;
          }
        }
      }
      if (formData.id) {
        this.$axios.restJsonPost(
          "docEntriesEdit",
          {
            id: formData.id,
            code: formData.typeCode,
          },
          formData,
          (res) => {
            this.prepare = true;
            var status = res.status;
            if (status.toString().substring(0, 1) == 2) {
              this.dialogObj.dialogOpen = false;
              this.$message.success("修改成功");
              this.getData();
            } else {
              this.$message.error(res.data.message);
            }
          }
        );
      } else {
        this.$axios.restJsonPost(
          "docEntries",
          { code: this.parent },
          formData,
          (res) => {
            this.prepare = true;
            const status = res.status;
            if (status.toString().substring(0, 1) == 2) {
              this.dialogObj.dialogOpen = false;
              this.$message.success("新增成功");
              this.getData();
            } else {
              this.$message.error(res.data.message);
            }
          }
        );
      }
    },
    getHeight() {
      this.tableHeight = window.innerHeight - (50 + 40 + 60 + 30 + 43) + "px";
      this.treeHeight = window.innerHeight - (50 + 40 + 35) + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.allTemplate {
  display: flex;
  .templateLeft {
    width: 230px;
    border: 1px solid #ccc;
    height: 100%;
    background: #f6f7fc;
    .company {
      height: 55px;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #666;
      background: linear-gradient(180deg, #fff, #f1f1f1);
      span {
        margin-right: 3px;
      }
    }
    ul {
      height: calc(100% - 55px);
      padding-right: 5px;
      box-sizing: border-box;
      background: #f6f7fc;
      overflow-y: auto;
      padding-top: 10px;
      .treeLi {
        line-height: 35px;
        color: #888;
        font-size: 15px;
        padding-left: 30px;
        cursor: pointer;
      }
      .treeLis {
        color: #333;
      }
    }
  }
  .templateRight {
    width: calc(100% - 240px);
    margin-left: 10px;
    .pages {
      text-align: right;
      padding: 5px 0;
      border: 1px solid #ddd;
    }
  }
}
</style>
